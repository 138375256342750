import React, { useState, useEffect } from "react";
import {
  PersonalBlogWrapper,
  AboutDetails,
  HomeTitle,
  Banner,
  HomeSubTitle,
  BannerTitle,
} from "./style";
import Posts from "./posts";
import Video from "./video";
import Zigzag from "../../components/zigzag/zigzag";
import Soundcloud from "../../components/soundcloud";
import Newsletter from "../../components/newsletter/newsletter";
import Slider from "./slider";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

type PersonalBlogProps = {};

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = (props) => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/dj-paul-banner.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      slogan: file(absolutePath: { regex: "/is-in-the-house.png/" }) {
        childImageSharp {
          fixed(width: 276) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);
  return (
    <PersonalBlogWrapper {...props}>
      <Banner>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="Dj Paul" />
        <BannerTitle>
          <h1 class="main-title">DJ PAUL</h1>
          <Image
            fixed={Data.slogan.childImageSharp.fixed}
            alt="Is in the House"
          />
        </BannerTitle>
      </Banner>
      <Zoom>
        <AboutDetails>
          <p>
            <b>Dj Paul</b> recorre el Perú presentándose en diferentes fiestas,
            matrimonios, eventos empresariales, eventos privados y discotecas.
            Dentro de estos eventos ha podido llegar a estar en los Carnavales
            de Huanchaco Trujillo, Aqua Ilo, Queen's Piura, cierres de año de
            mineras, Aura Arequipa, Aura Piura, Taita Cajamarca,
            Garden(Ex-Bikini) Huacho, en las fiestas de año nuevo en Zorritos
            Tumbes, Colán Piura, etc. Dj Paul ha sido y es residente de las
            mejores y más exclusivas discotecas de Lima. Teniendo en su
            trayectoria las siguientes residencias: Nikita, Aura, Joia, Mia,
            Dalí, Resident, Lyra...
          </p>
          <p>
            Hasta la pandemia Dj residente en
            <b> Cochinola, Bizarro, Loola y Qiu.</b>
          </p>
          <p>
            Dj Paul creó la empresa <b>Dj Paul Luces & Sonido S.A.C.</b> Una
            empresa responsable, puntual y capaz que brinda servicios de
            alquiler de equipos de sonido, luces y Dj la cual dispone de
            profesionales especializados con amplia experiencia en el sector de
            <b> eventos empresariales, matrimonios, privados y discotecas. </b>
            Enfocamos tu diversión y tranquilidad mediante el uso de equipos de
            última generación, sumándole una supervisión constante en la
            instalación y desarrollo del evento.
          </p>
          <p>
            <a
              href="https://www.zankyou.com.pe/f/dj-paul-sutcliffe-464278"
              rel="noreferrer"
              target="_blank"
            >
              <img
                width="100"
                alt="DJ Paul - Luces & Sonido - Zankyou"
                src="https://asset1.zankyou.com/img/sections/badgets/ziwa-winners/badge-ziwa2021.png"
              />
            </a>
            Los ZIWA son los <b>premios</b> más importantes del sector nupcial
            en el Perú debido a que tanto proveedores como novios eligen a los
            favoritos. Una vez más nos han elegido como uno de los{" "}
            <b>mejores profesionales de bodas del 2021.</b>
          </p>
        </AboutDetails>
      </Zoom>
      <Slider />
      <Fade right>
        <HomeTitle>en acción</HomeTitle>
        <HomeSubTitle>Dj Paul tocando antes de la pandemia. 🔥</HomeSubTitle>
      </Fade>
      <Video />
      <Zigzag />
      <Fade right>
        <HomeTitle>escucha</HomeTitle>
        <HomeSubTitle>mis últimos mixes 🎧</HomeSubTitle>
      </Fade>
      <Soundcloud
        mixes={[
          "https://soundcloud.com/user-697656371-419240302/mix-buena-bulla-vol-2",
          "https://soundcloud.com/user-697656371-419240302/cochimix-en-do-menor-ft-dj-cano",
          "https://soundcloud.com/user-697656371-419240302/mix-party-vibes",
          "https://soundcloud.com/user-697656371-419240302/dj-paul-mix-finde",
          "https://soundcloud.com/user-697656371-419240302/mix-buena-bulla",
          "https://soundcloud.com/user-697656371-419240302/dj-paul-mix-desmadre-dos",
        ]}
      />
      <Fade right>
        <HomeTitle>lo último</HomeTitle>
        <HomeSubTitle>
          mixes, playlists, la música que necesitas 💪🏻
        </HomeSubTitle>
      </Fade>
      <Posts />
      <Newsletter />
    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
