import styled from "styled-components";

export const PersonalBlogWrapper = styled.div``;

export const AboutDetails = styled.div`
  width: 60%;
  max-width: 100%;
  margin: auto;
  padding: 70px;
  @media only screen and (max-width: 992px) {
    width: 80%;
    padding: 40px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 15px;
  }
  img {
    float: right;
  }
`;

export const HomeTitle = styled.h2``;

export const HomeSubTitle = styled.h3``;

export const Banner = styled.div`
  position: relative;
`;

export const BannerTitle = styled.div`
  position: absolute;
  bottom: 180px;
  right: 620px;
  color: #fff;
  @media only screen and (max-width: 992px) {
    bottom: 100px;
    right: 220px;
  }
  @media only screen and (max-width: 600px) {
    bottom: 30px;
    right: 100px;
  }
  .main-title {
    font-weight: 900;
    font-size: 120px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: -3px;
    @media only screen and (max-width: 992px) {
      font-size: 100px;
    }
    @media only screen and (max-width: 600px) {
      font-size: 48px;
      letter-spacing: -1px;
    }
  }
  .main-title:after {
    content: "";
    width: 80px;
    height: 1px;
    background: #fff;
    display: block;
    margin: 8px 0;
  }
  span {
    display: inline-block;
    font-size: 22px;
    font-weight: 300;
  }
`;
