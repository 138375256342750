import styled from "styled-components";

export const VideoWrapper = styled.div`
  margin-bottom: 60px;
  .swiper-pagination {
    position: relative;
    bottom: 0;
  }
  .swiper-wrapper {
    width: 60%;
    @media (max-width: 600px) {
      width: 60%;
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;
  padding: 56.25% 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 1200px) {
  }
  @media (max-width: 990px) {
  }
`;
