import React from "react";
import Swiper from "react-id-swiper";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { SliderWrapper, SlideDiv } from "./style";

type SliderProps = {};

const Slider: React.FunctionComponent<SliderProps> = () => {
  const params = {
    slidesPerView: 3,
    centeredSlides: true,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  const sliderData = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "home-slider" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fixed(height: 320) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return (
    <SliderWrapper>
      <Swiper {...params}>
        {sliderData.allFile.edges.map((file) => (
          <SlideDiv>
            <Img
              key={file.id}
              fixed={file.node.childImageSharp.fixed}
              alt={file.node.name}
            />
            <div class="slider__content">
              <h1>{file.node.name.replace(/-+/g, " ")}</h1>
            </div>
          </SlideDiv>
        ))}
      </Swiper>
    </SliderWrapper>
  );
};

export default Slider;
