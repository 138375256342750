import styled from "styled-components";

export const SliderWrapper = styled.div`
  background-color: #d6c3c7;
  padding: 60px 0;
  @media only screen and (max-width: 600px) {
    padding: 30px 0;
  }
  .swiper-pagination {
    position: relative;
    bottom: 0;
  }
`;

export const SlideDiv = styled.div`
  .swiper-slide {
    width: auto;
  }
  .slider__content {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2vh;
    box-sizing: border-box;
    @media only screen and (max-width: 600px) {
    }
    h1 {
      color: white;
      font-weight: 900;
      font-size: 2vh;
      line-height: 0.85;
      margin-bottom: 0.75vh;
      pointer-events: none;
      text-shadow: 0 0.375vh 0.75vh rgba(0, 0, 0, 0.1);
    }
  }
`;
